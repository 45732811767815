<template>
  <section class="section">
    <p class="text-muted">{{ getTranslation('resultsFoundWithQuantity', pagination.total) }}</p>
    <table class="table-3 table-borderless">
      <thead class="table-3__head scroll-here">
      <tr class="table-3__row">
        <th colspan="4" class="table-3__cell  table__hcell">
          <form method="get" @submit.prevent="fetchLanguages(1)">
            <div class="field-advanced">
              <div class="field-advanced__inner">
                <div class="field-advanced__field-wrap">
                  <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('searchPhrase')">
                </div>

                <div class="field-advanced__right">
                  <button class="btn btn-primary">
                    <svg class="btn__icon">
                      <use xlink:href="#icon__search"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </th>
      </tr>
      <tr class="table-3__row">
        <th class="table-3__cell  table__hcell" style="width: 5%">#</th>
        <th class="table-3__cell  table__hcell" style="width: 20%">{{ getTranslation('key') }}</th>
        <th class="table-3__cell  table__hcell" style="width: 40%">{{ $lang.app.ru }}</th>
        <th class="table-3__cell  table__hcell" style="width: 40%">{{ $lang.app.en }}</th>
        <th class="table-3__cell  table__hcell" style="width:  2%"/>
      </tr>
      </thead>
      <tbody class="table-3__body">
      <tr v-for="(language,key) in languages" v-bind:key="key" class="table-3__row">
        <td class="table-3__cell">{{ language.id }}</td>
        <td class="table-3__cell">{{ language.name }}</td>
        <td v-for="translation in language.translates" class="table-3__cell">
          {{ translation.translation }}
        </td>
        <td class="table-3__cell">
          <CButton @click="initUpdate(language)">
            <CIcon class="action-icon" name="cil-pencil"/>
          </CButton>
        </td>
      </tr>
      </tbody>
    </table>
    <CPagination
        v-if="pagination.last_page > 1"
        class="mt-5"
        :active-page.sync="pagination.current_page"
        :pages="pagination.last_page"
        :limit="10"
    />

    <Modal
        :title="edit ? this.getTranslation('edit'):this.getTranslation('add')"
           size="lg"
           :show.sync="showModal"
           :centered="false"
           :ok-button-label="getTranslation('save')"
           @clickOK="save"
           @modalCancel="reset"
    >
      <form class="form w-100">
        <div class="form-group">
          <label class="form__label   form__label--block">{{ getTranslation('key') }}</label>
          <input :disabled="edit" class="field field--full-width" :class="{'is-invalid':$v.form.name.$error}" v-model.trim="form.name" type="text">
          <div v-if="$v.form.name.$error" class="invalid-feedback d-block">{{ getTranslation('requiredField') }}<span class="required">*</span></div>
        </div>
        <div v-for="locale in locales" class="form__group">
          <label class="form__label   form__label--block" for="shortArea">{{ $lang.app[locale] }}<span class="required">*</span></label>
          <textarea v-model.trim="form.translates[locale]" class="field  field--full-width  field--short-area" name="" id="shortArea" cols="30" rows="10"/>
        </div>
      </form>
    </Modal>

  </section>
</template>

<script>

import required from "vuelidate/lib/validators/required";

export default {
  name: "Index",
  data() {
    return {
      form: {
        name: '',
        translates: {}
      },
      pagination: {
        total: 0,
        last_page: '',
        current_page: ''
      },
      filterSettings: {
        visible: true
      },
      filterForm: {
        page: this.$route.query.page || 1,
      },
      edit: false,
      languages: [],
      locales: [],
      showModal: false
    }
  },
  watch:{
    'pagination.current_page'(val){
      this.fetchLanguages();
    }
  },
  created() {
    let actions = [];
    if ( this.hasPermission('lang.create') )
      actions.push({
        label: this.getTranslation('add'),
        action: this.setModal,
        icon: {
          icon: '#icon__square-add'
        }
      });

    this.setHeadings({
      title: this.getTranslation('languages'),
      actions: actions
    });

    this.fetchLanguages();
    this.fetchLocales();
  },
  methods: {
    save() {

      this.$v.$touch();

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');

      if ( this.edit )
        this.updateLanguage();
      else
        return this.axios.post(this.$urls.language.languages.url, this.form)
            .then(() => {
              this.snotifySuccess('saveSuccessMsg');
              this.showModal = false;
              this.fetchLanguages();
              this.reset();
              this.loadLocale();
            }).catch(() => {
            });
    },
    initUpdate(language) {
      this.edit = true;
      this.form.name = language.name;
      this.form.id = language.id;

      for ( let locale of this.locales ) {
        for ( let translate of language.translates ) {
          if ( locale === translate.locale )
            this.form.translates[locale] = translate.translation;
        }
      }

      this.setModal();
    },
    updateLanguage() {
      return this.axios.put(this.$urls.language.fetchLanguage.url.replace(':id', this.form.id), this.form)
          .then(() => {
            this.snotifySuccess('updateSuccessMsg');
            this.showModal = false;
            this.fetchLanguages();
            this.loadLocale();
            this.reset();
          }).catch(() => {
            this.snotifyError('serverError');
          });
    },
    fetchLanguages(page=null) {
      let filter = {
        page: page || this.pagination.current_page
      };
      if ( this.filterForm.query )
      {
        filter['query'] = this.filterForm.query;
      }

      this.$router.replace({ query: filter }).catch(() => {
      });

      return this.axios.get(this.$urls.language.languages.url, { params: filter }).then(response => {
        this.languages = response.data.data.items;
        this.pagination = response.data.data.pagination;
      }).catch(() => {
      });
    },
    reset() {
      this.edit = false;
      this.form = {
        name: '',
        translates: {}
      };
      this.$v.$reset();
    },
    setModal() {
      this.showModal = true;
      if ( !this.edit )
        this.reset();
    },
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>